export const environment = {
	production: false,

	web: {
		baseUrl: 'https://eventee.dev/',
	},

	socket: {
		socketUrl: 'https://socket.eventee.dev',
		questions_namespace: '/questions',
		polls_namespace: '/polls',
		reconnect: 3000
	},

	appApi: {
		baseUrl: `https://api.eventee.dev`,
		analyticsUrl: `https://analytics.eventee.dev`
	},

	scheduleConfig: {
		hourHeight: 250,
		startHour: 9,
		endHour: 21
	},

	socials: {
		facebookId: '1853045354914713',
		linkedInId: '7732ezyfw4gbqz',
        eventbriteId: 'UOD2FEXVUAWASGYA63',
		appleId: 'com.touchart.eventee.dev.login',
		mailChimpId: '162190170612',
		zoomId: 'joQc0YWpS9Obd4sXWVfcfw'
    },

    fastspring: {
        accessKey: 'LESD7BQYTHCROXTQQDXFCG',
        storefront: 'eventee.test.onfastspring.com/popup-test'
    },

    paddle: {
        sellerId: '12799'
    },

    bugsnag: {
        stage: 'staging',
        key: 'eab28ca5c34973bbd40a12c1cbe9e123'
    },

	productFruits: {
		key: 'XcW9njSveKccfUV8'
	},

	gleap: {
		key: '77asnFy92CoEI6xRwKPEpD8lFg5QWETA'
	},

    googleMaps: 'AIzaSyAWhE7yKRCYDVJhbZrHmfTbspwF5t5OBpI',
    recaptchaKey: '6LdV-_cdAAAAAMs99RqhinhNcXZ4DUCp6oSUwy0S',

    growthBook: {
		url: 'https://cdn.growthbook.io/api/features/dev_M5y97ohhtqDB547pPVx8KtWeHFYxm8xzG2WVXw6Z3A',
		key: null
	}
};
